<template>
  <div class="detail-section" :class="{ 'loading-size': isLoading }">
    <div class="m-2 pl-3 text-center" v-if="isLoading">
      <font-awesome-icon
        :icon="['fa', 'circle-notch']"
        class="fa-spin m-auto spinner fa-5x egx-partner-color"
      />
    </div>
    <template v-show="!isLoading">
      <ValidationObserver
        tag="form"
        @submit.prevent=""
        ref="prqSave"
        class="detail-section-form"
      >
        <NoConfiguredPRQs v-if="!fetchedData.length && !isLoading" :stepConfiguration="stepConfiguration"/>
        <div v-for="section in fetchedData" :key="section.id" v-else>
          <prq :section="section" @answerList="addToAnswerList" v-if="section.question_type === 'Question' && isPrq"/>
          <attachment :section="section" @answerList="addToAnswerList" v-if="section.question_type === 'Attachment' && isAttachment"/>
        </div>
        <step-button
          :steps-response="stepsResponse"
          @navigate="navigate"
          @save-data="saveAnswer"
          :data-save-required="true"
          v-if="!isLoading"
        />
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Prq from './Prqs.vue';
import Attachment from './Attachment.vue';
import GroupQuestion from '../../configurations/settings/group-questions.json';
import StepButton from '@/components/project/stepButton.vue';
import redirectPath from '@/shared/mixins/redirect-path';
import NoConfiguredPRQs from './NoConfiguredPRQs.vue';

export default {
  name: 'DetailSection',

  components: {
    StepButton,
    Multiselect,
    Prq,
    Attachment,
    NoConfiguredPRQs,
  },

  mixins: [redirectPath],

  props: {
    content: Object,
    stepsResponse: Object,
    navigate: Function,
    projectId: String,
    projectStatus: String,
    stepConfiguration: Object,
  },

  data() {
    return {
      responses: Object,
      query: this.$route.query,
      errMsg: '',
      isLoading: true,
      prqData: GroupQuestion,
      fetchedData: [],
      allAnswerList: {},
    };
  },

  created() {
    let params = { ...this.query };
    params.status = params.status || this.projectStatus;
    params.project_id = params.project_id || this.projectId;

    this.$store.dispatch('fulfill_integrate/get', params).then(() => {
      console.log('Success!');
      this.fetchedData = this.$store.state.fulfill_integrate.integrateData;

      this.fetchedData =
        this.fetchedData.sort(
          (a, b) => a.sorting_tag.localeCompare(b.sorting_tag, 'en', { numeric: true })
        );
      this.isLoading = false;
    }).catch((error) => {
      console.log(error.message);
      this.$notify({
        group: 'notice',
        text: error.message,
      });
    });
  },

  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings;
    },
    isPrq() {
      return this.query.type === 'prq';
    },

    isAttachment() {
      return this.query.type === 'attachment';
    },
  },

  methods: {
    saveAnswer(step) {
      this.$refs.prqSave.validate()
        .then((isValid) => {
          if (isValid) {
            if (this.query.type === 'attachment') {
              this.isLoading = true;
              this.$notify({
                group: 'notice',
                text: this.settings.fulfill_sync_details.messages.finish_steps,
                duration: 5000,
              });
              this.isLoading = false;

              if (step === 'nextStep') {
                this.navigate();
              } else if (step === 'linkStep') {
                this.$router.push(this.stepsResponse.add_btn_url);
              }

              return;
            }

            this.isLoading = true;
            const data = {
              answerList: this.allAnswerList,
              details: { ...this.query, project_id: this.projectId }
            }

            this.$store.dispatch('fulfill_integrate/post', data).then((response) => {
              this.$notify({
                group: 'notice',
                text: response.message,
                duration: 5000,
              });

              if (step === 'nextStep') {
                this.navigate();
              } else if (step === 'linkStep') {
                this.redirectPath(this.stepsResponse.add_btn_url);
              }
              // const newQuery = { ...this.query };
              // newQuery.status = 'testing2';
              // this.$router.push({path: 'test-page', query: newQuery }).catch(()=>{});
            }).catch((error) => {
              console.log(error.message);
              this.$notify({
                group: 'notice',
                text: error.message,
              });
            }).finally(() => {
              this.isLoading = false;
            });
          } else {
            console.log('Not Validated.');

            this.$notify({
              group: 'notice',
              text: this.settings.fulfill_sync_details.messages.validations,
              duration: 5000,
            });
          }
        });
    },

    addToAnswerList(groupId, answerObject) {
      if(this.allAnswerList[groupId] == null){
        this.allAnswerList[groupId] = answerObject;
      } else {
        this.allAnswerList[groupId] = { ...this.allAnswerList[groupId], ...answerObject };
      }
    },

    fetchData() {
      this.isLoading = true;
      this.$store.dispatch('fulfill_integrate/get', this.query).then(() => {
        console.log('Success!');
        this.fetchedData = this.$store.state.fulfill_integrate.integrateData;
        this.isLoading = false;
      }).catch((error) => {
        console.log(error.message);
        this.$notify({
          group: 'notice',
          text: error.message,
        });
      });

      this.fetchedData =
        this.fetchedData.sort(
          (a, b) => a.sorting_tag.localeCompare(b.sorting_tag, 'en', { numeric: true })
        );
    }
  },
};
</script>

<style lang="scss" scoped>
.loading-size {
  min-width: 70rem;
}
@media (max-width: 768px) {
  .loading-size {
    min-width: 10rem;
  }
}
</style>
